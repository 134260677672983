import React, {useEffect} from "react";
import {
    ActionType,
    ColumnType,
    CursedColumn,
    CursedTable,
    Filter,
    FreeTextFilter,
    GetCursedParams,
    KCard,
    KContainer,
    KModal,
    KTableLoader
} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import {tableGetCursedParams} from "../utils/commons";
import {CustomerConsumptionStats} from "../types/customer";
import {collapseAllNested, defaultStyles, JsonView} from "react-json-view-lite";
import 'react-json-view-lite/dist/index.css';
import {ENVIRONMENT} from "../utils/constants";

export interface StateProps {
    consumptionStats?: CustomerConsumptionStats[];
    consumptionStatsNext?: string;
}

export interface DispatchProps {
    onDownloadCSV: () => Promise<void>;
    onGetConsumptionStats: (query: GetCursedParams) => Promise<{ next?: string, prev?: string }>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const GodMode: React.FC<Props> = ({consumptionStats, consumptionStatsNext, onDownloadCSV, onGetConsumptionStats}: Props) => {
    const tableId = "cursedConsumption_" + ENVIRONMENT;
    const [showModal, setShowModal] = React.useState<CustomerConsumptionStats | undefined>();

    useEffect(() => {
        (async () => {
            await onGetConsumptionStats(tableGetCursedParams(tableId));
        })().catch(console.error);
    }, [onGetConsumptionStats, tableId]);

    const actions: ActionType<CustomerConsumptionStats>[] = [
        {
            name: <><i className="fal fa-eye action-icon"/> <Translate value="superAdmin.details"/></>,
            handler: async (customer) => {
                setShowModal(customer);
            },
        }
    ]

    const filters: Filter[] = [
        new FreeTextFilter("customerEmail", <Translate value={"superAdmin.email"}/>),
        new FreeTextFilter("partner", <Translate value={"superAdmin.partner"}/>),
    ];

    return (
        <KContainer>
            <Row>
                <Col lg={10} md={10} sm={12}>
                    <KCard header={<Translate value="superAdmin.cardTitle"/>}>
                        {consumptionStats ? (
                            <CursedTable
                                id={tableId}
                                keyField={"customerId"}
                                data={consumptionStats}
                                cursorNext={consumptionStatsNext}
                                checkboxes={false}
                                loaderFunc={(params) => onGetConsumptionStats(params)}
                                filterDefinition={filters}
                                pageSizes={[5, 10]}
                                globalActions={[
                                    {
                                        name: <><i className="fal fa-download action-icon"/> <Translate value="superAdmin.exportCustomerCSV"/></>,
                                        handler: async () => {
                                            await onDownloadCSV();
                                        },
                                    }

                                ]}
                            >
                                <CursedColumn colid={"customerEmail"} name={"superAdmin.email"} type={ColumnType.GENERIC} classes="text-start"/>
                                <CursedColumn colid={"partner"} name={"superAdmin.partner"} type={ColumnType.GENERIC} classes="text-start"/>
                                <CursedColumn colid={"activeSolutions"} name={"superAdmin.activeSolutions"} type={ColumnType.GENERIC} classes="text-start" render={(datum: CustomerConsumptionStats) => {
                                    const solutions = datum.availability.filter((a) => a.activeSubs.length > 0).map((a) => a.solution);
                                    return Array.from(new Set(solutions).values()).join(", ");
                                }}/>
                                <CursedColumn colid={"activeProducts"} name={"superAdmin.activeProducts"} type={ColumnType.GENERIC} classes="text-start" render={(datum: CustomerConsumptionStats) => {
                                    const products = datum.availability.filter((a) => a.activeSubs.length > 0).map((a) => a.productInternalId);
                                    return Array.from(new Set(products).values()).join(", ");
                                }}/>
                                <CursedColumn colid={"currentConsumed"} name={"superAdmin.currentConsumed"} type={ColumnType.GENERIC} classes="text-start" render={(datum: CustomerConsumptionStats) => {
                                    const consumed = datum.availability.filter((a) => a.activeSubs.length > 0).map((a) => a.current.consumed);
                                    return consumed.join(", ");
                                }}/>
                                <CursedColumn colid={"lastUsage"} name={"superAdmin.lastUsage"} type={ColumnType.GENERIC} classes={"text-start"} render={(datum: CustomerConsumptionStats) => {
                                    const lastUsages = datum.availability.map((a) => a.lastConsumption ? `${a.lastConsumption.getFullYear()}-${a.lastConsumption.getMonth()+1}-${a.lastConsumption.getDate()}` : "NONE");
                                    return lastUsages.join(", ");
                                }}/>
                                <CursedColumn colid={"actions"} type={ColumnType.ACTIONS} actions={actions} classes="text-end" colspan={2}/>
                            </CursedTable>
                        ) : <KTableLoader/>}
                    </KCard>
                </Col>
            </Row>
            <KModal show={!!showModal} header={<Translate value="superAdmin.details"/>} onHide={() => setShowModal(undefined)}>
                {showModal ? (
                    <JsonView data={showModal} style={defaultStyles} shouldExpandNode={collapseAllNested} />
                ) : null}
            </KModal>
        </KContainer>
    );
}
