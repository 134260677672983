import React, {useEffect, useState} from "react";
import {BillingInfo, Customer} from "../types/customer";
import {Col, Row} from "react-bootstrap";
import {
    KButton,
    KBUTTON_SIZE,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KForm,
    KInput,
    KSelect,
    KSELECT_BACKCOLOR,
    KSELECT_SIZE,
    KSpace,
    KSpinner,
    KSpinnerSize
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import $ from "jquery";
import {isVAT, showAlert} from "../utils/commons";
import countries from "i18n-iso-countries";
import {useAuth0} from "@auth0/auth0-react";
import {getProvincesFromCountry} from "../utils/provinces";

export interface StateProps {
    locale: string;
    customer?: Customer;
    billingInfo?: BillingInfo;
}

export interface DispatchProps {
    onSaveBillingInfo: (id: number, bi: BillingInfo) => Promise<void>;
    onGetCustomer: () => Promise<void>;
    onGetBillingInfo: () => Promise<void>;
}

export interface InnerProps {
    onSubmitted?: () => void;
    buttonText?: JSX.Element;
    confirmEmail?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const BillingInfoForm: React.FC<Props> = ({onGetBillingInfo, billingInfo, confirmEmail = true, buttonText, locale, customer, onSaveBillingInfo, onSubmitted, onGetCustomer}) => {
    const [submitting, setSubmitting] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>(billingInfo?.address.countryISOCodeAlpha2);
    const {user} = useAuth0();
    useEffect(() => {
        if (!customer) {
            onGetCustomer().catch(console.log);
        } else if (!billingInfo) {
            onGetBillingInfo().catch(console.log);
        } else {
            setSelectedCountry(billingInfo.address.countryISOCodeAlpha2);
        }
    }, [billingInfo, customer, onGetBillingInfo, onGetCustomer]);

    const emailValidation = () => {
        if (confirmEmail) {
            const emailInput = $("#email");
            const confirmEmailInput = $("#confirmEmail");
            if (emailInput.val() !== confirmEmailInput.val()) {
                (emailInput.get(0) as HTMLInputElement).setCustomValidity("error");
                (confirmEmailInput.get(0) as HTMLInputElement).setCustomValidity("error");
            } else {
                (emailInput.get(0) as HTMLInputElement).setCustomValidity("");
                (confirmEmailInput.get(0) as HTMLInputElement).setCustomValidity("");
            }
        }
    };

    function vatValidation(value: string): boolean {
        return isVAT(value);
    }

    const countryNames = countries.getNames(locale, {select: "official"});

    return (!customer || !billingInfo) ? <h2><KSpinner size={KSpinnerSize.lg}/></h2> : (
        <KForm onSubmit={async (values) => {
            try {
                await onSaveBillingInfo(customer.id, {
                    email: values.email as string,
                    invoiceEmail: values.invoiceEmail as string,
                    ragioneSociale: values.name as string,
                    pIVA: values.vat as string,
                    // codiceFiscale: isCF(values.vat as string) ? values.vat as string : undefined,
                    address: {
                        city: values.city as string,
                        province: values.state as string,
                        countryISOCodeAlpha2: values.country as string,
                        postalCode: values.zip as string,
                        street: (!values.street && !values.number) ? undefined : `${values.street || ""}, ${values.number || ""}`,
                    },
                });
                if (onSubmitted) {
                    onSubmitted();
                }
            } catch (err) {
                showAlert(I18n.t("billingInfo.saveError"), "error");
            }
        }} onSubmitting={(s) => setSubmitting(s)}>
            <Row>
                <Col>
                    <KInput id="name" defaultValue={billingInfo.ragioneSociale || ""} label={<Translate value="billingInfo.name"/>} required={true} errorMessage={<Translate value="billingInfo.required"/>}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <KInput id="vat" defaultValue={billingInfo.pIVA || billingInfo.codiceFiscale || ""} label={<Translate value="billingInfo.vat"/>} required={true}
                            errorMessage={new Map().set("validate", <Translate value="billingInfo.vatError"/>)}
                            validate={(value: string) => vatValidation(value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <KInput id="street" defaultValue={billingInfo.address.street?.split(",")[0] || ""} label={<Translate value="billingInfo.street"/>} required={true}/>
                </Col>
                <Col lg={2}>
                    <KInput id="number" defaultValue={billingInfo.address.street?.split(",")[1] || ""} label={<Translate value="billingInfo.number"/>} required={true}/>
                </Col>
                <Col lg={2}>
                    <KInput id="zip" defaultValue={billingInfo.address.postalCode || ""} pattern={"^[0-9]{5}$"} label={<Translate value="billingInfo.zip"/>} required={true}/>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <KInput id="city" defaultValue={billingInfo.address.city || ""} label={<Translate value="billingInfo.city"/>} required={true}/>
                </Col>
                <Col lg={4}>
                    <KSelect<string>
                        id="state"
                        options={Object.keys(getProvincesFromCountry(selectedCountry)).map((key) => ({value: key, label: getProvincesFromCountry(selectedCountry)[key], searchText: getProvincesFromCountry(selectedCountry)[key]}))}
                        defaultValue={Object.keys(getProvincesFromCountry(selectedCountry)).indexOf(billingInfo.address.province || "") === -1 ? undefined : billingInfo.address.province}
                        clearable={true}
                        searchable={true}
                        underline={true}
                        size={KSELECT_SIZE.lg}
                        backColor={KSELECT_BACKCOLOR.gray}
                        label={<Translate value="billingInfo.state"/>}
                        minWidth={0}
                        required={true}
                    />
                </Col>
                <Col lg={4}>
                    <KSelect<string>
                        id="country"
                        options={Object.keys(countryNames).filter((cn) => cn === "IT").map((cn) => ({value: cn, label: countryNames[cn], searchText: countryNames[cn]}))}
                        defaultValue={billingInfo.address.countryISOCodeAlpha2}
                        clearable={true}
                        searchable={true}
                        underline={true}
                        size={KSELECT_SIZE.lg}
                        backColor={KSELECT_BACKCOLOR.gray}
                        label={<Translate value="billingInfo.country"/>}
                        minWidth={0}
                        required={true}
                        onChange={(value) => setSelectedCountry(value as string)}
                    />
                </Col>
            </Row>
            <Row style={{display: "none"}}>
                <Col>
                    <KInput id="email" defaultValue={billingInfo.email || user?.email || ""} label={<Translate value="billingInfo.email"/>} required={true}
                            errorMessage={<Translate value="billingInfo.emailError"/>}
                            onChange={() => emailValidation()} type={"email"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <KInput id="invoiceEmail" type={"email"}
                            errorMessage={<Translate value="billingInfo.emailError"/>}
                            defaultValue={billingInfo.invoiceEmail || ""}
                            label={<Translate value="billingInfo.invoiceEmail"/>} required={false}/>
                </Col>
            </Row>
            {confirmEmail ? (
                <Row>
                    <Col>
                        <KInput id="confirmEmail" label={<Translate value="billingInfo.confirmEmail"/>} required={true}
                                onChange={() => emailValidation()} type={"email"}
                        />
                    </Col>
                </Row>
            ) : null}
            <KSpace/>
            <Row>
                <Col>
                    {submitting ? <h2><KSpinner size={KSpinnerSize.lg}/></h2> : (
                        <KButton id={"save-billing-info"} text={buttonText || (<><i className="fal fa-angle-double-right" style={{fontSize: 24, position: "relative", top: 3}}/> <Translate value="billingInfo.proceed"/></>)}
                                 type={KBUTTON_TYPE.submit} variant={KBUTTON_VARIANT.primary} size={KBUTTON_SIZE.nm}
                        />
                    )}
                </Col>
            </Row>
        </KForm>
    );
}
